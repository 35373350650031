import { IUatStatus } from "../models/uatStatusModels";
import apiHealthService from "../services/apiHealthService";
import backgroundJobStatusServce from "../services/backgroundJobStatusServce";
import { GET_PROD_STATUS, GET_PROD_STATUS_ERROR, GET_PROD_STATUS_SUCCESS } from "../types/prodStatusTypes";

export const getProdStatus = (baseUrl: string) => {
    return function (dispatch: any) {
        dispatch({
            type: GET_PROD_STATUS
        });

        Promise.all([
            apiHealthService.getApiHealth(baseUrl),
            backgroundJobStatusServce.getBackgroundJobStatus(baseUrl)
        ]).then((values: any[]) => {

            let uatStatus: IUatStatus = {
                apiHealth: values[0],
                backgroundJobStatuses: values[1]
            }

            dispatch({
                type: GET_PROD_STATUS_SUCCESS,
                payload: uatStatus
            });

        }).catch((error: any) => {
            dispatch({
                type: GET_PROD_STATUS_ERROR,
                payload: error.message
            })
        })
    }
}
import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link} from "react-scroll";
import { Route, Redirect, Switch, HashRouter } from "react-router-dom";

import logo from './assets/images/logo-white.png';
import { Home } from './pages/home';

function App() {
  return (
    <HashRouter>
      <Navbar expand="lg">
        <Navbar.Brand href="/"><img src={logo} alt="Logo" height={40} /></Navbar.Brand>
        <span className="project-name">Status App</span>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="">
          <Nav className="ml-md-auto">
            <Link className="nav-link" activeClass="active" to="prodStatusSection"
              spy={true} smooth={true} offset={-70} duration={500}>PROD</Link>
            <Link className="nav-link" activeClass="active" to="uatStatusSection"
              spy={true} smooth={true} duration={500}>UAT</Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <Switch>
        <Route path="/" exact component={Home} />
        <Route path='/home' render={() => <Redirect to="/" />} />
      </Switch>

    </HashRouter>
  );
}

export default App;

import { IProdStatusState } from "../models/prodStatusModels";
import { GET_PROD_STATUS, GET_PROD_STATUS_ERROR, GET_PROD_STATUS_SUCCESS, ProdStatusActionTypes } from "../types/prodStatusTypes";


const INITIAL_STATE: IProdStatusState = {
    prodStatus: {
        apiHealth: null,
        backgroundJobStatuses: []
    },
    loading: false,
    error: false,
    errorMessage: ''
}

const prodStatusReducer = (state: IProdStatusState = INITIAL_STATE, action: ProdStatusActionTypes) => {
    switch (action.type) {
        case GET_PROD_STATUS:
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: ''
            }
        case GET_PROD_STATUS_SUCCESS:
            return {
                ...state,
                prodStatus: action.payload,
                loading: false,
                error: false,
                errorMessage: ''
            }
        case GET_PROD_STATUS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: 'Loading PROD status failed. ' + action.payload
            }
        default:
            return state;
    }
}

export default prodStatusReducer;
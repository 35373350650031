import { IUatStatus } from '../models/uatStatusModels';


export const GET_UAT_STATUS = "GET_UAT_STATUS";
export const GET_UAT_STATUS_SUCCESS = "GET_UAT_STATUS_SUCCESS";
export const GET_UAT_STATUS_ERROR = "GET_UAT_STATUS_ERROR";


interface IGetUatStatusAction {
    type: typeof GET_UAT_STATUS;
    payload: null;
}

interface IGetUatStatusSuccessAction {
    type: typeof GET_UAT_STATUS_SUCCESS;
    payload: IUatStatus;
}

interface IGetUatStatusErrorAction {
    type: typeof GET_UAT_STATUS_ERROR;
    payload: string;
}

export type UatStatusActionTypes = IGetUatStatusAction | IGetUatStatusSuccessAction | IGetUatStatusErrorAction;
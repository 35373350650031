import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

export interface IStatusDisplayItemProps {
    itemHeaderTitle: string;
    itemHeaderResult?: string;
    itemBody?: any;
    isHaveBody: boolean;
}

export const StatusDisplayItem: React.FC<IStatusDisplayItemProps> = ({
    itemHeaderTitle,
    itemHeaderResult,
    itemBody,
    isHaveBody
}) => {

    const [isShowBody, setIsShowBody] = React.useState(false);


    const showHideBody = () => {
        if (isHaveBody === false) {
            return;
        }
        setIsShowBody(!isShowBody);
    }

    return (
        <div>
            <div onClick={showHideBody}
                className={"status-display-item-header " + (isHaveBody ? "clickable-header" : "") +
                    (itemHeaderResult === "Healthy" ? "healthy" : "")}>
                <Row>
                    <Col sm={6}>
                        <p className="item-title">{itemHeaderTitle}</p>
                        <div className="caret"></div>
                    </Col>
                    <Col sm={6} className="text-right">
                        <p className="item-result">{itemHeaderResult}</p>
                    </Col>
                </Row>
            </div>
            {
                isHaveBody === true && isShowBody &&
                <div className="status-display-item-body">
                    {itemBody}
                </div>
            }
        </div>
    )
}
import { IUatStatus } from "../models/uatStatusModels"
import apiHealthService from "../services/apiHealthService"
import backgroundJobStatusServce from "../services/backgroundJobStatusServce"
import { GET_UAT_STATUS, GET_UAT_STATUS_ERROR, GET_UAT_STATUS_SUCCESS } from "../types/uatStatusTypes"

export const getUatStatus = (baseUrl:string) => {
    return function (dispatch: any) {
        dispatch({
            type: GET_UAT_STATUS
        })

        Promise.all([
            apiHealthService.getApiHealth(baseUrl),
            backgroundJobStatusServce.getBackgroundJobStatus(baseUrl)
        ]).then((values: any[]) => {

            let uatStatus: IUatStatus = {
                apiHealth: values[0],
                backgroundJobStatuses: values[1]
            }

            dispatch({
                type: GET_UAT_STATUS_SUCCESS,
                payload: uatStatus
            })

        }).catch((error: any) => {
            dispatch({
                type: GET_UAT_STATUS_ERROR,
                payload: error.message
            })
        })
    }
}
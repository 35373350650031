import React, { useEffect } from 'react';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import loaderImage from '../assets/images/310.gif';
import { getProdStatus } from '../actions/prodStatusActions';
import { getUatStatus } from '../actions/uatStatusActions';
import { StatusDisplayItems } from '../components/statusDisplayItems';
import { IAppMainState } from '../models/commonModels';
import { BASE_API_URL_PROD, BASE_API_URL_UAT } from '../common-constants';

export const Home = () => {
    const dispatch = useDispatch();
    const prodStatusState = useSelector((state: IAppMainState) => state.prodStatusState);
    const uatStatusState = useSelector((state: IAppMainState) => state.uatStatusState);

    useEffect(() => {
        dispatch(getProdStatus(BASE_API_URL_PROD));
        dispatch(getUatStatus(BASE_API_URL_UAT));
    }, []);

    return (
        <Container className="mt-5 mb-5">
            <Row className="mb-5"  id="prodStatusSection">
                <Col>
                    <h5 className="status-page-title">PROD environment</h5>
                    {
                        prodStatusState.error === true &&
                        <Alert variant="danger">
                            {prodStatusState.errorMessage === "" ? "An error occurred loading PROD status" : prodStatusState.errorMessage}
                        </Alert>
                    }
                    {
                        prodStatusState.loading === false &&
                        <StatusDisplayItems apiHealth={prodStatusState.prodStatus.apiHealth} backgroundJobStatuses={prodStatusState.prodStatus.backgroundJobStatuses} />
                    }

                </Col>
            </Row>
            <Row className="mb-5" id="uatStatusSection">
                <Col>
                    <h5 className="status-page-title">UAT environment</h5>
                    {
                        uatStatusState.error === true &&
                        <Alert variant="danger">
                            {uatStatusState.errorMessage === "" ? "An error occurred loading UAT status" : uatStatusState.errorMessage}
                        </Alert>
                    }
                    {
                        uatStatusState.loading === false &&
                        <StatusDisplayItems apiHealth={uatStatusState.uatStatus.apiHealth} backgroundJobStatuses={uatStatusState.uatStatus.backgroundJobStatuses} />
                    }
                </Col>
            </Row>
            {
                (prodStatusState.loading === true || uatStatusState.loading === true) &&
                <div className="loading">
                    <div className="img-container"><b>Please wait..</b>&nbsp;&nbsp;&nbsp;<img src={loaderImage} alt="" /></div>
                </div>
            }
        </Container>
    )
}

import { IUatStatusState } from "../models/uatStatusModels";
import { GET_UAT_STATUS, GET_UAT_STATUS_ERROR, GET_UAT_STATUS_SUCCESS, UatStatusActionTypes } from "../types/uatStatusTypes";

const INITIAL_STATE: IUatStatusState = {
    uatStatus: {
        apiHealth: null,
        backgroundJobStatuses: []
    },
    loading: false,
    error: false,
    errorMessage: ''
}

const uatStatusReducer = (state: IUatStatusState = INITIAL_STATE, action: UatStatusActionTypes) => {
    switch (action.type) {
        case GET_UAT_STATUS:
            return {
                ...state,
                loading: true,
                error: false,
                errorMessage: ''
            }
        case GET_UAT_STATUS_SUCCESS:
            return {
                ...state,
                uatStatus: action.payload,
                loading: false,
                error: false,
                errorMessage: ''
            }
        case GET_UAT_STATUS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: 'Loading UAT status failed. ' + action.payload
            }
        default:
            return state;
    }

}

export default uatStatusReducer;
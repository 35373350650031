import { IProdStatus } from "../models/prodStatusModels";

export const GET_PROD_STATUS = "GET_PROD_STATUS";
export const GET_PROD_STATUS_SUCCESS = "GET_PROD_STATUS_SUCCESS";
export const GET_PROD_STATUS_ERROR = "GET_PROD_STATUS_ERROR";

interface IGetProdStatusAction {
    type: typeof GET_PROD_STATUS;
    payload: null;
}

interface IGetProdStatusSuccessAction {
    type: typeof GET_PROD_STATUS_SUCCESS;
    payload: IProdStatus;
}

interface IGetProdStatusErrorAction {
    type: typeof GET_PROD_STATUS_ERROR;
    payload: string;
}

export type ProdStatusActionTypes = IGetProdStatusAction | IGetProdStatusSuccessAction | IGetProdStatusErrorAction;
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';

import { IApiHealth } from '../models/apiHealthModels';
import { IBackgroundJobStatus } from '../models/backgroundJobStatusModels';
import { StatusDisplayItem } from './statusDisplayItem';


export interface IStatusDisplayItemsProps {
    apiHealth: IApiHealth | null;
    backgroundJobStatuses: IBackgroundJobStatus[];
}

export const StatusDisplayItems :React.FC<IStatusDisplayItemsProps> =({
    apiHealth,
    backgroundJobStatuses
})=> {

    const renderbackgroundJobStatusDisplayItemBody = (backgroundJobStatus: IBackgroundJobStatus) => {
        return (
            <div>
                <Row>
                    <Col sm={6}>
                        <p>Last heart beat</p>
                    </Col>
                    <Col sm={6} className="text-right">
                        <p>{new Date(backgroundJobStatus.lastHeartBeat).toLocaleString()}</p>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <p>Latest run</p>
                    </Col>
                    <Col sm={6} className="text-right">
                        <p>{new Date(backgroundJobStatus.latestRun).toLocaleString()}</p>
                    </Col>
                </Row>
            </div>
        )
    }


        return (
            <div>
                {
                    apiHealth !== null &&
                    <StatusDisplayItem
                        itemHeaderTitle="API Health"
                        itemHeaderResult={apiHealth.status}
                        isHaveBody={false} />
                }
                {
                    apiHealth !== null &&
                    <StatusDisplayItem
                        itemHeaderTitle="API Context Health"
                        itemHeaderResult={apiHealth.results.AhnContext.status}
                        isHaveBody={false} />
                }
                {
                    (backgroundJobStatuses.sort((a, b) => a.jobName.localeCompare(b.jobName)))
                        .map((backgroundJobStatus: IBackgroundJobStatus, index: number) => {
                            return (<StatusDisplayItem key={index}
                                itemHeaderTitle={backgroundJobStatus.jobName}
                                itemBody={renderbackgroundJobStatusDisplayItemBody(backgroundJobStatus)}
                                isHaveBody={true} />)
                        })
                }
            </div>
        );


}